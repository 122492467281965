import React from 'react';
import logo from './prisme-studio.png';
import './App.css';
import TypingConsoleComponent from './TypingConsoleComponent';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo"/>
        <TypingConsoleComponent></TypingConsoleComponent>
      </header>
    </div>
  );
}

export default App;
