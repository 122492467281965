import React from "react";
import { TypeAnimation } from "react-type-animation";

function TypingConsoleComponent() {
  return (
    <TypeAnimation
      sequence={[
        "Illuminez votre créativité",
        500,
        "Explorez un monde de couleurs",
        500,
        "L'art prend vie en pixels",
        500,
        "Vos rêves, notre inspiration",
        500,
        "Créez, innovez, brillez",
        500,
        "L'art de la création numérique",
        500,
        "Transformez votre vision en réalité",
        500,
        "La palette infinie pour votre imagination",
        500,
        "Chaque pixel compte",
        500,
        "L'avenir de la création commence ici",
        500,
      ]}
      speed={55}
      deletionSpeed={60}
      cursor={true}
      repeat={Infinity}
      style={{
        fontSize: ".8em",
        display: "inline-block",
      }}
    />
  );
}

export default TypingConsoleComponent;
